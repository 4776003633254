// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://905add238042460182b9380e788c401c@o511855.ingest.sentry.io/4504044051234816',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.3 : 1.0,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,

  enabled: process.env.NODE_ENV !== 'development',

  // The ad-blocker like uBlock Origin will block the sentry reporting endpoint.
  // Sentry supports tunneling.
  // https://docs.sentry.io/platforms/javascript/troubleshooting/
  tunnel: '/api/error-reporting/tunnel',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
