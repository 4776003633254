import 'styles/globals.css'
import 'components/ui/DatePicker/style.css'
import { appWithTranslation } from 'next-i18next'

import type { AppProps } from 'next/app'
import { UserProvider } from '@supabase/auth-helpers-react'
import supabase from 'utils/supabaseClient'

const MyApp = ({ Component, pageProps }: AppProps) => (
  <UserProvider supabaseClient={supabase}>
    <Component {...pageProps} />
  </UserProvider>
)

export default appWithTranslation(MyApp)
